@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  /* COLORS */
  --color-primary-blue: #1a5192;
  --color-primary-lightBlue: #7ed1e7;
  --color-primary-purple: #692c87;
  --color-primary-charcoal: #373d4d;
  --color-accent-red: #e05d62;
  --color-accent-green: #86ccab;
  --color-accent-yellow: #fbb460;
  --color-accent-cream: #f4f5c5;
  --color-accent-purple: #b65ec7;
  --color-accent-darkpurple: #491e5e;
  --color-accent-black: #181d24;
  --color-accent-grey: #bfcdd8;
  --color-accent-alsogrey: #a0a6b3;
  --color-accent-dark-grey: #4f586c;
  --color-accent-light-grey: #cfcfcf;

  /* FONTS */
  --font-tech: 'Lato', sans-serif;
  --font-branding: 'Work Sans', sans-serif;

  /* DESKTOP */
  --font-size-title-1: 2.5rem; /* 40px */
  --font-size-title-2: 1.5rem; /* 24px */
  --font-size-title-3: 1.125rem; /* 18px */
  --font-size-large: 1rem; /* 16px */
  --font-size-medium: 0.875rem; /* 14px */
  --font-size-small: 0.75rem; /* 12px */
  --font-size-micro: 0.625rem; /* 10px */
  /* MOBILE */
  --font-size-title-1-m: 1.875rem; /* 30px */
  --font-size-title-2-m: 1.25rem; /* 20px */
  --font-size-title-3-m: 1rem; /* 16px */
  --font-size-large-m: 0.875rem; /* 14px */
  --font-size-medium-m: 0.75rem; /* 12px */
  --font-size-small-m: 0.625rem; /* 10px */
  --font-size-micro-m: 0.5rem; /* 8px */

  /* SPACING */
  --spacing-xLarge: 4rem; /* 64px */
  --spacing-large: 3rem; /* 48px */
  --spacing-medium: 1.5rem; /* 24px */
  --spacing-small: 1rem; /* 16px */
  --spacing-tiny: 0.5rem; /* 8px */
  --spacing-bitsy: 0.25rem; /* 4px */
}
