.wj-viewer{
  height: calc(100% - 135px);
}

.dark {
    display: block;
    box-sizing: border-box;
    color: #7c7c7c;
    background: #858585;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

.dark .wj-control{
  background:#eee;
}

.dark.wj-viewer .wj-control .wj-applybutton{
  background: #eee;
}

  .light {
    display: block;
    box-sizing: border-box;
    color: #7c7c7c;
    background: #F1F1F1;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

 .dark.wj-viewer .wj-toolbar {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #636363;
    position: absolute;
    margin-top: -50px;
  }
  
  .dark.wj-viewer .wj-pageview {
    outline: none;
    position: relative;
    background: #858585;
    height: 100%;
    width: 100%;
    text-align: center;
    -webkit-overflow-scrolling: touch;
  }

  .dark .wj-tabpane.active{
    border-left:#636363;
  }

  .wj-viewer .wj-viewer-splitter button {
    height: 100%;
    padding: 0 2px;
    min-width: 12px;
    background: #fafafa;
    border-left: 1px solid #c8c8c8;
    border-right: 1px solid #c8c8c8;
    border-top: none;
    border-bottom: none;
  }

  .dark.wj-viewer .wj-tabpane{
    background: #B2B2B2;
    
  }

  .dark.wj-viewer .wj-toolbarwrapper span{
    color: #B2B2B2;
  }

  .dark .wj-viewer-splitter .wj-btn{
    background: #B2B2B2;
  }

  .dark .wj-btn.wj-state-active svg.wj-svg-btn path{
    fill:#CCCCCC;
    /* stroke: red; */
  }
  .dark .wj-btn.wj-state-active svg.wj-svg-btn polygon{
    fill:#CCCCCC;
  }
  
  .light .wj-btn.wj-state-active svg.wj-svg-btn path{
    fill:#232323;
    /* stroke: red; */
  }
  .light .wj-btn.wj-state-active svg.wj-svg-btn polygon{
    fill:#232323;
  }

  .light .wj-btn.wj-state-active svg.wj-svg-btn rect{
    fill:#232323;
  }

  a:focus{
    border: 1px solid #7c7c7c  !important;
  }