.slider-enter {
transform: translateX(200px);
transition: transform 1000ms;
}
.slider-exit {
transform: translateX(0%);
transition: transform 1000ms;
}
.slider-icon-enter {
transform: translateX(200px);
transition: transform 1000ms;
box-shadow: 5px 2px 15px #434343;;
}
.slider-icon-exit {
transform: translateX(0%);
transition: transform 1000ms;

}

.slide-up-visible {
    transform: translateY(0%);
    margin-bottom: 30px;
    transition: margin-bottom 1000ms, transform 1000ms;
}

.slide-up-hidden {
    transform: translateY(-100%);
    margin-bottom: -100px;
    transition: margin-bottom 1000ms, transform 1000ms;
}


.carousel-group{
    height: 100%;
    width: 100%;
    position:relative;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-content: center;
}

.carousel-enter {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  transform: translate(100%, 0);
  opacity: 0;
}

.carousel-enter-active {
  transform: translate(0, 0);
  transition: all 1000ms ease;
  opacity: 1;
}

.carousel-exit{
  transform: translate(0, 0);
  opacity: 1;
}

.carousel-exit-active{
  transition: all 1000ms ease;
  transform: translate(-100%, 0);
  opacity: 0;
}

.carousel-slide-Left-enter {    
    opacity: 1;
    transform:translateX(120%);
    transition: all 300ms ease-in;
}

.carousel-slide-Left-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: all 300ms ease-in;
}
.carousel-slide-Left-exit {
    position: absolute;
    opacity: 1;
    transform:  translateX(100%);
}
.carousel-slide-Left-exit-active {
    opacity: 0;
    position: absolute;
    transform: translateX(-100%);
    transition: all 300ms ease-in;
}

.carousel-slide-Right-enter {    
    opacity: 1;
    transform: translateX(-100%);
    transition: all 300ms ease-in;
}

.carousel-slide-enter-done {    
    opacity: 1;
    transform: translateX(0%);
    transition: all 300ms ease-in;
}

.carousel-slide-Right-enter-active {
    transform: translateX(0%);
    opacity: 1;
    transition: all 300ms ease-in;
}
.carousel-slide-Right-exit {
    position: absolute;
    opacity: 1;
    transform:  translateX(0%);
}
.carousel-slide-Right-exit-active {
    opacity: 0;
    position: absolute;
    transform: translateX(100%);
    transition: all 300ms ease-in;
}

.sidebar-slide-enter{
    transform: translateX(-100%);
}
.sidebar-slide-enter-active{
    transform: translateX(0%);
    transition: all 1000ms linear;
}
.sidebar-slide-enter-done{
    transform: translateX(0%);
}

.sidebar-slide-exit-done{
    transform: translateX(-100%);
}
.sidebar-slide-exit-active{
    transform: translateX(0%);
    transition: all 1000ms linear;
}


/* signal loader styles */
.signal-loader{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .signal-loader  path:nth-child(1){
    animation-delay: 200ms;
    animation-duration: 1.5s;
    animation-name: appear;
  /*   animation-direction: alternate; */
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  
  .signal-loader  path:nth-child(2){
    animation-delay: 100ms;
    animation-duration: 1.5s;
    animation-name: appear;
    animation-timing-function: linear;
  /*   animation-direction: alternate; */
    animation-iteration-count: infinite;
  }
  
  .signal-loader  path:nth-child(3){
    animation-duration: 1.5s;
    animation-name: appear;
    animation-timing-function: linear;
  /*   animation-direction: alternate; */
    animation-iteration-count: infinite;
  }
  
  @keyframes appear{
    from{
      opacity: 0;
    }
    25%{
      opacity:1;
    }
    75%{
      opacity: 1;
    }
    80%{
      opacity: 0;
    }
    to {
      opacity: 0;
    }
  }


  .claimCard{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .expanded{
    height: 100%;
  }

  .collapsed{
    height: 0px;
  }
  
/* CONNECTION SWITCHER */
.switcher-enter{
  transition: all .5s;
  height: calc(100%  - 90px);
  transform: translateY(0%);
  transform-origin: top;
}

.switcher-exit{
  transition: all .5s;
  height: 0;
  transform: translateY(-100%);
  opacity: 0
}

/* SITE HEADER */
.search-input-enter {
  transition: all .3s ease;
  width: 100%;
}

.search-input-exit {
  transition: all .3s ease;
  width: 0;
}