@import url('https://fonts.googleapis.com/css2?family=B612+Mono&family=Source+Code+Pro:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding:wght@400;700&display=swap');

.bar > rect,
.bar > g > rect {
  stroke-width: 0%;
}

/* humana: '#4E8416', medicare: '#BE3E3D', medicaid: '#076A73'} */

.wj-legend > g > .wj-label {
  transform: translateX(-0.1%);
}

.wj-flexpie > svg > .wj-legend > text {
  transform: translateX(-0.1%);
  /* font-size: .85em; */
}

.wj-flexpie > svg > .wj-legend {
}

/* #region Insurance-specific CSS */

.aetna > rect {
  fill: #7d3f97;
  stroke: none;
  /* rx: 3px; */
}

.bcbs > rect {
  fill: #008bcc;
  stroke: none;
  /* rx: 3px; */
}

.cigna > rect {
  fill: #f89820;
  stroke: none;
  /* rx: 3px; */
}

.cignacom > rect {
  fill: #0170bc;
  stroke: none;
}

.cignamca > rect {
  fill: #00ce43;
  stroke: none;
}

.commercial > rect {
  fill: #fed520;
  stroke: none;
}

.humana > rect {
  fill: #4e8416;
  stroke: none;
  /* rx: 3px; */
}

.medicaid > rect {
  fill: #076a73;
  stroke: none;
  /* rx: 3px; */
}

.medicare > rect {
  fill: #be3e3d;
  stroke: none;
  /* rx: 3px; */
}

.mcareadv > rect {
  fill: #ff7f00;
  stroke: none;
}

.pehp > rect {
  fill: #003f72;
  stroke: none;
}

.tricare > rect {
  fill: #842b2a;
  stroke: none;
}

.uhc > rect {
  fill: #002776;
  stroke: none;
  /* rx: 3px; */
}

.umr > rect {
  fill: #6abd48;
  stroke: none;
}

.va > rect {
  fill: #f50a44;
  stroke: none;
}

.wellcare > rect {
  fill: #00a0a3;
  stroke: none;
}

/* #endregion */

.wj-state-selected {
  stroke-width: 0px !important;
}

.chart .wj-data-labels text {
  fill: white;
  /* transform:translateX(-.1em); */
}

.gcr-chart .wj-data-labels text {
  fill: white;
  font-size: 0.75em;
  transform: translateX(0.1em);
}

.mobile-label {
  font-size: 12px;
  fill: #222;
}
