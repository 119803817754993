div {
  font-size: 16px;
}

/*Fonts*/
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');


/*General*/
html{
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  width: -webkit-fill-available;
  margin: 0;
  padding: 0;
}

body{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  line-height: 1;
  overflow-x: hidden;
  /* font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300))); */
}

#root{
  width: 100%;
  height: 100%;
}

/* .App {
  height: 100%;
  width: 100%;
  text-align: center;
  font-family: 'Work Sans', 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color:rgba(153, 153, 153);
  display: grid;
  grid-template-areas:  'header header'
                        'tabs viewer';
  grid-template-rows: 80px calc(100% - 80px);
  grid-template-columns: 95px calc(100% - 95px);
}

@media screen and (max-width:1024px) {
  .App{
      display: grid;
      grid-template-areas:  'header'
                            'viewer';
      grid-template-rows: 80px calc(100% - 80px);
      grid-template-columns: 100%;
    }

} */

textarea, input{
  font-family: 'Work Sans', 'Arial', sans-serif;
  resize: none;
}

.simple-trans-main > div {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 800;
  font-size: 25em;
  will-change: transform, opacity;
  text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #00000030;
}



#launchpad{
  height: 20%;
}

button {
  font-family: 'Work Sans', 'Arial', sans-serif;
}

.listIsOpen {
  display: block;
}

.listIsClosed {
  display: none;
}

/* SCROLLBAR */

/* Scrollbar container */
::-webkit-scrollbar {
  opacity: 1;
  width: 17px;
}

::-webkit-scrollbar-button {
  border-radius: 50px;
  display: none;
  margin-right: 5px;
}

/* Scrollbar body */
::-webkit-scrollbar-thumb {
  background-color: #738693;
  border-radius: 15px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  min-height: 30px;
}

::-webkit-scrollbar-track {
  display: none;
  margin-right: 5px;
}

::-webkit-scrollbar-trackpiece {
  opacity: 1;
  margin-right: 5px;
}

::-webkit-scrollbar-corner {
  display: none;
  margin-right: 5px;
}

.scrollhost {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollhost::-webkit-scrollbar{
  display: none;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

.hideScroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: auto;
}

.scrollhost::-webkit-scrollbar {
  display: none;
}

.hide {
  display: flex;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.8s;
	-moz-transition: max-height 0.8s;
	transition: max-height 0.8s;
}

.show {
  transition: max-height 1s;
  display: flex;
  max-height: 4000px;
  height: 100%;
}

.sideHidden {
  display: block;
}

.sideShowing {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* .wj-flexchart 
.wj-data-labels 
.wj-data-label {
  fill: white;
} */

/* Flexbox Class */
.flexRow {
  display: flex;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.invisibleButton {
  height: fit-content;
  width: fit-content;
  margin: 0;
  padding: 0;
  background: none;
  color: inherit;
  outline: none;
  border: none;
  cursor: pointer;
}